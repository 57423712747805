<template>
  <v-flex
    xs12
    :md3="!fullscreen"
    :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'"
  >
    <v-card @click="$router.push(`/purchases-view/${purchase._id}`)" elevation="24" dark>
      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide) in purchase.item.images" :key="slide">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte">{{
              purchase.item.name
            }}</v-list-item-title>
            <v-list-item-subtitle class="fonte orange--text">Data: {{
              $moment(purchase.created_at).format('DD/MM/YYYY [às] HH:mm')
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!priceless"
              @click=""
              :color="$theme.primary"
              large
              rounded
              dark
              class="pa-1 ma-0"
            >
              <v-avatar size="36" class="ml-0 mr-1">
                <v-img :src="$theme.token_img"></v-img>
              </v-avatar>
              <span class="fonte mr-2"> {{ purchase.item.token_price }} </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-expansion-panels flat>
            <v-expansion-panel>
                <v-expansion-panel-header class="fonte green--text"> Veja o Andamento </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-timeline align-top dense>
                        <v-timeline-item :color="status.color" small :key="status" v-for="status in purchase.status">
                            <span class="fonte"> {{ status.status }}</span>
                            <span class="grey--text fonte"> ({{ $moment(status.date).format('DD/MM/YYYY [às] HH:mm') }}) </span>
                        </v-timeline-item>
                    </v-timeline>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  props: ["purchase", "priceless", "fullscreen"],
};
</script>
